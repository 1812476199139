<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 34"
        fill="none"
        width="32"
        height="34"
    >
        <path
            d="M26.5521 16.264C27.2047 16.6515 27.2047 17.5961 26.5521 17.9836L9.5106 28.1038C8.84401 28.4997 8 28.0193 8 27.244L8 7.00358C8 6.2283 8.84402 5.7479 9.5106 6.14376L26.5521 16.264Z"
            fill="white"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
